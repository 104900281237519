import React from "react"
import Banner from "../components/banner/banner"
import Nav from "../components/navigation/navigation"
import Footer from "../components/footer/footer"
import SEO from "../components/seo"
import { useState } from "react"
const NotFoundPage = ({ data }) => {
  const [showNav, setShowNav] = useState(false)
  return (
    <div>
      <SEO title="404: Not found" />
      <Banner
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}
        title="Not Found"
        summary="We're sorry, the page you're looking for doesn't exist."
        bgClass="not-found"
        cta="Return"
        openNav={() => setShowNav(true)}></Banner>
      {showNav ? (
        <Nav
          logo={data.strapiGlobal.logo.publicURL}
          close={() => setShowNav(false)}></Nav>
      ) : null}
      <Footer
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}></Footer>
    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundQuery {
    strapiGlobal {
      email
      logo {
        publicURL
      }
    }
  }
`
